import * as React from 'react'
import Layout from '../layout/layout'

const IndexPage = () => {

  return (
    <Layout pageTitle="JVR RESEARCH" pageHeading="JVR RESEARCH">
      <p>Exploring NFTs, P2E and Metaverses. Just doing my own research and sharing.</p>
      <a href="https://discord.gg/rUVbXbzc">Discord</a>
      <a href="https://twitter.com/jvr_research">Twitter</a>
    </Layout>
  )
  
}

export default IndexPage